import * as Sentry from "@sentry/nuxt";
import config, { getIsMaintenanceActive } from "~/config";
import type { Contract, ContractAsCustomer } from "~/src/generated-sources/core";
import getCurrentTariffId from "~/utils/customer-area/getContractCurrentTariff";
import getRedeemedRecommendations from "~/utils/customer-area/getRedeemedRecommendations";
import hashString from "~/utils/tracking/hashString";

const getTariffIds = (contracts: Contract[]) => contracts.map(getCurrentTariffId);

export default defineNuxtRouteMiddleware(async (_to) => {
  if (getIsMaintenanceActive()) {
    return navigateTo(config.maintenancePage);
  }

  if (process.client) {
    // The asyncUserData flag is used to determine if the middleware should fetch data asynchronously
    // This is used for the entry point of the customer area, so that the page can be rendered without waiting for the data
    const isFetchingAsync = (_to.meta?.meta as any)?.asyncUserData;
    const customerStore = useCustomerStore();
    const { $brain } = useNuxtApp();

    if (!customerStore.customer?.id) {
      try {
        const customer = await $brain.customer.customer.getCustomer();

        if (customer.data) {
          customerStore.setCustomer(customer.data);
        }

        // Append user id to Sentry user context
        Sentry.setUser({ id: hashString(customer.data.id) });
      } catch (e) {
        console.warn(e);
      }
    }

    /**
     * Load and save customer related data (not included the customer itself).
     */
    const loadAndSaveCustomerRelatedData = async () => {
      try {
        const contractRequest = await $brain.customer.contracts.getCustomerContracts();
        const contracts = contractRequest.data.items as ContractAsCustomer[];

        if (contracts) {
          customerStore.setContracts(contracts);

          // get tariffs for contracts
          const tariffIds = getTariffIds(contracts);
          if (tariffIds.length !== 0 && tariffIds.every(Boolean)) {
            const retrievedTariffs = await getTariffsById(tariffIds);
            if (retrievedTariffs?.length) {
              customerStore.setTariffs(retrievedTariffs);
            }
          }
        }

        const recommendationsRequest =
          await $brain.customer.customer.getRecommendations();
        const recommendations = recommendationsRequest.data.items || [];

        customerStore.setRecommendations(getRedeemedRecommendations(recommendations));
      } catch (e) {
        console.warn(e);
      }
    };

    if (!customerStore.contracts.length) {
      if (isFetchingAsync) {
        // Load and save customer related data asynchronously in the background
        loadAndSaveCustomerRelatedData();
      } else {
        await loadAndSaveCustomerRelatedData();
      }
    }
  }
});
